const imgs = document.querySelectorAll("img");
imgs.forEach((img) => (img.loading = "lazy"));
const lazyDiv = document.querySelectorAll(".lazy");
lazyDiv.forEach((div) => {
  const img = div.querySelector("img");
  function loaded() {
    // show image
    div.classList.add("loaded");
    document
      .querySelectorAll(".loaded")
      .forEach((load) => (load.style.backgroundImage = "none"));
  }

  if (img?.complete) {
    loaded();
    // console.log("completed");
  } else img?.addEventListener("load", loaded);
});
